<template>
    <div id="ecole" class="">
        <section class="page__section">
      <div class="cov">
        <div class="cov__content">
            <div class="cov__title">
                <!-- <img
            :src="
              strapi_url + '/uploads/medium_zappyschool_Copie_16215f81f2.png'
            "
            alt="ZappySchool"
          /> -->
                <h1>{{ data.title }}</h1>
            </div>
          
          <h2>
            <SvgIcon :icon="'adress'" size="2.4"></SvgIcon> {{ data.subtitle }}
        </h2>
        <div class="cov__f">
            <h3>En bref :</h3>
          <ul>
            <li v-for="h of data.accroche">
              <SvgIcon :icon="h.icon" size="3"></SvgIcon>
              {{ h.text }}
            </li>
          </ul>
        </div>

          <div class="stage__header--cta">
            <button class="btn btn--primary" @click="scrollTo('offre')">
              Découvrir notre offre
            </button>
          </div>
  
        </div>
        <div class="cov__image">
          <img
            :src="strapi_url + data.image"
            alt=""
          />
        </div>
      </div>
    </section>
    </div>
   
  </template>
  
  <script setup>
  import Svg from "@/components/utils/Svg.vue";
  import SvgIcon from "@/components/utils/SvgIcon.vue";

  import {scrollTo} from "@/composables/utils/scroll";
  const strapi_url = process.env.VUE_APP_STRAPI_DOMAIN;
  const data = {
    title:"Soutien scolaire pour élèves du secondaire",
    subtitle:"Au Fort-Jaco à Uccle",
    image:"/uploads/zappyschool_b2bd043446.jpg?updated_at=2023-04-17T13:26:57.039Z",
    accroche:[
        {
            icon:"boost",
            text:"8 ans d'expérience"
        },
        {
            icon:"bill",
            text:"Pas de frais d'inscription"
        },
        {
            icon:"horaire",
            text:"Ouvert dimanche et jours fériés"
        }
    
    ]
  }
  </script>
  
  <style lang="scss">
  @import "@/style/main";
  .cov{
    margin-top: $total-top-height;
    width: 100%;
    padding: 5rem 0;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      column-gap: 4rem;
  
      @include respond(phone){
          grid-template-columns: 1fr;
          row-gap: 3rem;
      }

    &__title{
        // background: linear-gradient(to top left ,rgba($color-primary,.5), rgba($color-primary,.2));
        // padding: 1rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        & img{
            margin-right: 2rem;
            height: 8rem;
            width: auto;
        }
        & h1 {
            font-size: 4rem;
            font-weight: 900;
            line-height: 4.6rem;
            display: flex;
            align-items: center;
            position: relative;
        }
    }

    &__image{
        display: flex;
        align-items: center;
        & img {
          width: 100%;
          height: auto;
        }
    }

    &__f{
        margin:4rem 0;
        // border-radius: 5px;
     
        // background-color: $color-white;
        // box-shadow: $shadow-light;

        & h3{
            
            margin-bottom: 2rem;
            font-size: 2.2rem;
            font-weight: 900;
            // color:$color-primary;
        }

        & ul {
            padding-left: 3rem;
        border-left: 4px solid $color-primary;
          display: flex;
          flex-direction: column;
        }
  
        & li {
          display: flex;
          font-size: 2rem;
  
          & svg{
              margin-right: 2rem;
          }
          &:not(:last-child) {
            margin-bottom: 3rem;
          }
        }
    }

    &__content{
        padding:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        & h2 {
            color:$color-secondary;
          font-size: 2.2rem !important;
          margin-bottom: 2rem !important;
          display: flex;
           align-items: center;

           & svg{
            margin-right: 1.2rem;
           }
        }

      
  
     
    }

    
  }
  
  .stage {
    &__header {
      padding: 5rem 0;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      column-gap: 4rem;
  
      @include respond(phone){
          grid-template-columns: 1fr;
          row-gap: 3rem;
      }
  
      &--cta{
        margin-top: 3rem;
      }
  
      &--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        & h2 {
          font-size: 3rem !important;
          margin-bottom: 4rem !important;
        }
  
        & ul {
          display: flex;
          flex-direction: column;
        }
  
        & li {
          display: flex;
          font-size: 2rem;
  
          & svg{
              margin-right: 2rem;
          }
          &:not(:last-child) {
            margin-bottom: 3rem;
          }
        }
      }
  
      &--img {
        & img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  </style>
  