<template>
  <button :class="{ 'reserv__dates--selected': isSelected }">
    <date-calendar :date="date" :selected="isSelected"></date-calendar>
  </button>
</template>

<script setup>
import DateCalendar from "@/components/form/DateCalendar";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

const props = defineProps(["selectedDate", "date"]);
const isSelected = ref(false);
const date = props.date;

watch(
  () => props.selectedDate,
  (n, o) => {
    if (n === date) isSelected.value = true;
    else isSelected.value = false;
  }
);

if (props.selectedDate === date) isSelected.value = true;
</script>

<style></style>
