<template>
    <div>
        <label v-if="data.label" class="form__label">
            {{data.label}}
            <helper-input v-if="data.helper" :message="data.helper"></helper-input>
        </label>
        <span class="form__error">{{data.error}}</span>
        
        <div v-if="selected" class="form__item">
            <div  class="form__selected">
                {{ selected.nom_eleve }} 
                <button class="btn__icon" @click="deleteSelect">
                    <SvgIcon icon="delete" size="2"></SvgIcon>
                </button>
            </div>
        </div>
      
        <template v-else>
            <input
                :type="data.input || 'text'" 
                :placeholder="data.placeholder" 
                class="form__input" 
                v-model="inputs" 
                :disabled="data.disabled" 
                :style="`color: ${data.color ? data.color: 'inherit'}`"
                @input="updateValue"
            >
            <ul class="form__suggest" v-if="suggestions">
                <template v-if="suggestions.length">
                    <li v-for="sugg of suggestions" @click="selectSuggestion(sugg)">
                        {{ sugg.nom_eleve }}
                    </li>
                </template>
                <template v-else>
                    <li>Aucun résultat pour "{{ inputs }}"</li>
                </template>
            </ul>
    </template>

    </div>
</template>

<script setup>
import { postData } from '@/composables/api/apiRequest';
import SvgIcon from '@/components/utils/SvgIcon';
import{ ref, computed} from 'vue';
import HelperInput from './HelperInput';

const props = defineProps(['data', 'modelValue']);
const emit = defineEmits(['updateForm']);
const suggestions = ref(null);
const selected = ref(props.data.value ? props.data.value : null);
    
const inputs  = ref(props.modelValue);
// const inputs = computed({ 
//     get: () => props.modelValue, 
//     set: (value) => emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: value}) 
// }) 

const updateValue = async () => {

    await search();
    // emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: inputs.value}) 
    
}

const selectSuggestion = (suggestion) => {
    console.log(suggestion);
    selected.value = suggestion;
    emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: suggestion}) 
    suggestions.value = null;
    inputs.value = '';
}

const deleteSelect = () => {
    selected.value = null;
    emit('updateForm', {field: `${props.data.name}${props.data.field ? '.'+props.data.field: ''}`, value: null}) 
    suggestions.value = null;
    inputs.value = '';
}

/**
 * We check if the pressed key is a number of not.
 */
const search = async() => {
    if(inputs.value.length>2){
        const res = await postData(props.data.url, {
            value: inputs.value
        })
        suggestions.value = res.data.suggestions;
    }else{
        suggestions.value = null;
    }
}
        
    
</script>

<style lang="scss">
@import '@/style/main';
@import '@/style/components/form';
</style>