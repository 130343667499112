<template>
  <div
    class="table__line table__line--header"
    :class="schema.color ? `u-bck-${schema.color}` : ''"
  >
    <div
      v-for="col in schema.structure"
      :key="col.attr"
      class="table__col table__col--header"
      :class="`table__col--${col.width} ${
        col.sortable ? 'table__col--click' : ''
      }`"
      @click="col.sortable ? sortData(col.slug) : false"
    >
      <b v-if="Boolean(isBold)">{{ col.label }}</b>
      <template v-else>{{ col.label }}</template>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  schema: Object,
  isBold: {
    type: Boolean,
    default: false,
  },
});
console.log(props.schema);
</script>
