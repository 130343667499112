<template>
  <component
    v-if="iconComponent"
    :is="iconComponent"
    :class="`icons-svg ${className ? className : ''}`"
    :style="iconStyle"
  ></component>
</template>

<script setup>
import { computed } from "vue";

import AccountSvg from "@/components/utils/icons/AccountSvg.vue";
import AddCalendarSvg from "@/components/utils/icons/AddCalendarSvg.vue";
import AddPersonSvg from "@/components/utils/icons/AddPersonSvg.vue";
import AdressSvg from "@/components/utils/icons/AdressSvg.vue";
import AtelierSvg from "@/components/utils/icons/AtelierSvg.vue";
import AttentionSvg from "@/components/utils/icons/AttentionSvg.vue";
import BackSvg from "@/components/utils/icons/BackSvg.vue";
import BillSvg from "@/components/utils/icons/BillSvg.vue";
import BookingSvg from "@/components/utils/icons/BookingSvg.vue";
import BoostSvg from "@/components/utils/icons/BoostSvg.vue";
import ClockSvg from "@/components/utils/icons/ClockSvg.vue";
import CmsSvg from "@/components/utils/icons/CmsSvg.vue";
import ConferenceSvg from "@/components/utils/icons/ConferenceSvg.vue";
import ConnectSvg from "@/components/utils/icons/ConnectSvg.vue";
import CroissanceSvg from "@/components/utils/icons/CroissanceSvg.vue";
import DeleteSvg from "@/components/utils/icons/DeleteSvg.vue";
import DocumentSvg from "@/components/utils/icons/DocumentSvg.vue";
import DocumentsSvg from "@/components/utils/icons/DocumentsSvg.vue";
import EcouteSvg from "@/components/utils/icons/EcouteSvg.vue";
import EditSvg from "@/components/utils/icons/EditSvg.vue";
import EducationSvg from "@/components/utils/icons/EducationSvg.vue";
import EmailSvg from "@/components/utils/icons/EmailSvg.vue";
import EuroSvg from "@/components/utils/icons/EuroSvg.vue";
import EuroTableSvg from "@/components/utils/icons/EuroTableSvg.vue";
import FactureSvg from "@/components/utils/icons/FactureSvg.vue";
import FeedbackSvg from "@/components/utils/icons/FeedbackSvg.vue";
import FileSvg from "@/components/utils/icons/FileSvg.vue";
import GiveupSvg from "@/components/utils/icons/GiveupSvg.vue";
import GraduationSvg from "@/components/utils/icons/GraduationSvg.vue";
import HandshakeSvg from "@/components/utils/icons/HandshakeSvg.vue";
import HelpSvg from "@/components/utils/icons/HelpSvg.vue";
import HomeSvg from "@/components/utils/icons/HomeSvg.vue";
import HoraireSvg from "@/components/utils/icons/HoraireSvg.vue";
import InfoSvg from "@/components/utils/icons/InfoSvg.vue";
import JumpSvg from "@/components/utils/icons/JumpSvg.vue";
import LoginSvg from "@/components/utils/icons/LoginSvg.vue";
import LogoutSvg from "@/components/utils/icons/LogoutSvg.vue";
import MessageSvg from "@/components/utils/icons/MessageSvg.vue";
import ParametersSvg from "@/components/utils/icons/ParametersSvg.vue";
import ParticulierSvg from "@/components/utils/icons/ParticulierSvg.vue";
import PdfSvg from "@/components/utils/icons/PdfSvg.vue";
import PersonSvg from "@/components/utils/icons/PersonSvg.vue";
import PhoneSvg from "@/components/utils/icons/PhoneSvg.vue";
import PlusSvg from "@/components/utils/icons/PlusSvg.vue";
import ProfilSvg from "@/components/utils/icons/ProfilSvg.vue";
import ProfSvg from "@/components/utils/icons/ProfSvg.vue";
import RespectSvg from "@/components/utils/icons/RespectSvg.vue";
import RightArrowSvg from "@/components/utils/icons/RightArrowSvg.vue";
import SendSvg from "@/components/utils/icons/SendSvg.vue";
import StageSvg from "@/components/utils/icons/StageSvg.vue";
import Stage1Svg from "@/components/utils/icons/Stage1Svg.vue";
import StarSvg from "@/components/utils/icons/StarSvg.vue";
import StatSvg from "@/components/utils/icons/StatSvg.vue";
import TeacherSvg from "@/components/utils/icons/TeacherSvg.vue";
import TransactionSvg from "@/components/utils/icons/TransactionSvg.vue";
import UploadSvg from "@/components/utils/icons/UploadSvg.vue";
import ValidateSvg from "@/components/utils/icons/ValidateSvg.vue";
import WebsiteSvg from "@/components/utils/icons/WebsiteSvg.vue";


const props = defineProps(["className", "icon", "size"]);
const icons = [
  {
    icon: "account",
    componentObject: AccountSvg,
  },
  {
    icon: "addCalendar",
    componentObject: AddCalendarSvg,
  },
  {
    icon: "addPerson",
    componentObject: AddPersonSvg,
  },
  {
    icon: "adress",
    componentObject: AdressSvg,
  },
  {
    icon: "atelier",
    componentObject: AtelierSvg,
  },
  {
    icon: "attention",
    componentObject: AttentionSvg,
  },
  {
    icon: "back",
    componentObject: BackSvg,
  },
  {
    icon: "bill",
    componentObject: BillSvg,
  },
  {
    icon: "booking",
    componentObject: BookingSvg,
  },
  {
    icon: "boost",
    componentObject: BoostSvg,
  },
  {
    icon: "clock",
    componentObject: ClockSvg,
  },
  {
    icon: "cms",
    componentObject: CmsSvg,
  },
  {
    icon: "conference",
    componentObject: ConferenceSvg,
  },
  {
    icon: "connect",
    componentObject: ConnectSvg,
  },
  {
    icon: "croissance",
    componentObject: CroissanceSvg,
  },
  {
    icon: "delete",
    componentObject: DeleteSvg,
  },
  {
    icon: "document",
    componentObject: DocumentSvg,
  },
  {
    icon: "documents",
    componentObject: DocumentsSvg,
  },
  {
    icon: "ecoute",
    componentObject: EcouteSvg,
  },
  {
    icon: "edit",
    componentObject: EditSvg,
  },
  {
    icon: "education",
    componentObject: EducationSvg,
  },
  {
    icon: "email",
    componentObject: EmailSvg,
  },
  {
    icon: "euro",
    componentObject: EuroSvg,
  },
  {
    icon: "euroTable",
    componentObject: EuroTableSvg,
  },
  {
    icon: "facture",
    componentObject: FactureSvg,
  },
  {
    icon: "feedback",
    componentObject: FeedbackSvg,
  },
  {
    icon: "file",
    componentObject: FileSvg,
  },
  {
    icon: "giveup",
    componentObject: GiveupSvg,
  },
  {
    icon: "graduation",
    componentObject: GraduationSvg,
  },
  {
    icon: "handshake",
    componentObject: HandshakeSvg,
  },
  {
    icon: "help",
    componentObject: HelpSvg,
  },
  {
    icon: "home",
    componentObject: HomeSvg,
  },
  {
    icon: "horaire",
    componentObject: HoraireSvg,
  },
  {
    icon: "info",
    componentObject: InfoSvg,
  },
  {
    icon: "jump",
    componentObject: JumpSvg,
  },
  {
    icon: "login",
    componentObject: LoginSvg,
  },
  {
    icon: "logout",
    componentObject: LogoutSvg,
  },
  {
    icon: "message",
    componentObject: MessageSvg,
  },
  {
    icon: "parameters",
    componentObject: ParametersSvg,
  },
  {
    icon: "particulier",
    componentObject: ParticulierSvg,
  },
  {
    icon: "pdf",
    componentObject: PdfSvg,
  },
  {
    icon: "person",
    componentObject: PersonSvg,
  },
  {
    icon: "phone",
    componentObject: PhoneSvg,
  },
  {
    icon: "plus",
    componentObject: PlusSvg,
  },
  {
    icon: "profil",
    componentObject: ProfilSvg,
  },
  {
    icon: "prof",
    componentObject: ProfSvg,
  },
  {
    icon: "respect",
    componentObject: RespectSvg,
  },
  {
    icon: "right-arrow",
    componentObject: RightArrowSvg,
  },
  {
    icon: "send",
    componentObject: SendSvg,
  },
  {
    icon: "stage",
    componentObject: StageSvg,
  },
  {
    icon: "stage1",
    componentObject: Stage1Svg,
  },
  {
    icon: "star",
    componentObject: StarSvg,
  },
  {
    icon: "stat",
    componentObject: StatSvg,
  },
  {
    icon: "teacher",
    componentObject: TeacherSvg,
  },
  {
    icon: "transaction",
    componentObject: TransactionSvg,
  },
  {
    icon: "upload",
    componentObject: UploadSvg,
  },
  {
    icon: "validate",
    componentObject: ValidateSvg,
  },
  {
    icon: "website",
    componentObject: WebsiteSvg,
  },
];
// Computed property to find the icon component
const iconComponent = computed(
  () =>
    icons.find((iconProps) => iconProps.icon === props.icon)?.componentObject
);

// Computed property for dynamic styles
const iconStyle = computed(() =>
  props.size ? { height: `${props.size}rem`, width: `${props.size}rem` } : {}
);
</script>

<style lang="scss">
@import "@/style/main";

.icons-svg {
  fill: currentColor !important;
  //   transform: rotate(180deg) scaleX(-1);
}
</style>
