<template>
  <div
    class="table__line table__line--header"
    :class="schema.color ? `u-bck-${schema.color}` : ''"
  >
    <div
      v-for="col in schema.structure"
      :key="col.attr"
      class="table__col table__col--header"
      :class="`table__col--${col.width} ${
        col.sortable ? 'table__col--click' : ''
      }`"
      @click="col.sortable ? sortData(col.slug) : false"
    >
      {{ col.label }}
    </div>
  </div>
</template>
<script setup>
const props = defineProps(["schema"]);
console.log(props.schema)
</script>
