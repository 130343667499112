<template>
  <div v-if="data.start !== '0'" class="room">
    <div class="room__title">
      <h3>
        {{
          newReservation.start
            ? `Horaire  sélectionné: ${newReservation.start} - ${newReservation.end}`
            : "Veuillez choisir une tranche horaire"
        }}
      </h3>
    </div>
    <div class="room__content">
      <ul>
        <li
          v-for="i of calendar"
          :class="{
            'room--line': i.time.split(':')[1] === '00',
            'room--line-large': step >= 60 ? true : false,
            'room--interval': i.time.split(':')[1] !== '00',
            'room--booked': i.booked,
            'room--booked-user': i.bookedByUser,
            'room--booking': i.booking,
          }"
          :data-time="i.time"
          @mouseover="hoverTime(i.time)"
          @mousedown="enterClick(i.time)"
          @mouseup="leaveClick(i.time)"
          @touchstart="enterClick(i.time)"
          @touchmove="touchTime"
          @touchend="leaveTouch(i.time)"
        >
          <div v-if="i.time.split(':')[1] === '00'" class="room--hour">
            {{ i.time }}
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="room__closed">
    {{data.emptyMessage}}
  </div>
</template>

<script setup>
import moment from "moment";
import { ref } from "@vue/reactivity";
import { onMounted, watch } from "@vue/runtime-core";
import { useStore } from "vuex";

const props = defineProps(["data", "modelValue"]);
const emit = defineEmits(["updateForm"]);

const formatTime = "HH:mm";
const store = useStore();

const hovering = ref(false);
const step = props.data.step; //minutes
const minTime = props.data.minTime ? props.data.minTime : step;
const newReservation = ref({
  start:
    props.data.value && props.data.value.start ? props.data.value.start : "",
  end: props.data.value && props.data.value.end ? props.data.value.end : "",
});

const calendar = ref([]);

onMounted(() => {
  generateSchedule();
  console.log(calendar.value);
});

watch(
  () => props.date,
  (n, o) => {
    generateSchedule();
  }
);

const generateSchedule = () => {
  calendar.value = [];

  const start = moment(props.data.start, formatTime);
  const end = moment(props.data.end, formatTime);
  const iteration = moment.duration(end.diff(start)).asMinutes() / step;
  const availabilities = props.data.availabilities
    ? props.data.availabilities
    : false;

  for (let i = 0; i <= iteration; i++) {
    const time = start.add(i === 0 ? 0 : step, "minutes");
    const timeFormat = time.format(formatTime);
    const availabilitiesSlot = availabilities
      ? availabilities.filter((el) => el.start == timeFormat).length
        ? availabilities.filter((el) => el.start == timeFormat)[0]
        : false
      : false;
    calendar.value.push({
      booked: availabilitiesSlot ? availabilitiesSlot.full : false,
      time: timeFormat,
    });
  }
  updateReservationTime();
};

// RESERVATION
const enterClick = (time) => {
  calendar.value = calendar.value.map((el) => {
    if (el.time === time && !el.booked) {
      newReservation.value.start = time;

      newReservation.value.end = moment(time, formatTime)
        .add(minTime, "minutes")
        .format(formatTime);

      console.log(newReservation.value.end, calendar.value.at(-1).time);
      if (newReservation.value.end > calendar.value.at(-1).time) {
        newReservation.value.end = calendar.value.at(-1).time;
      }
      el.booking = true;
      hovering.value = true;
    } else {
      el.booking = false;
    }
    return el;
  });
  updateReservationTime();
};

/**
 * We select time while the user has his click down
 */
const hoverTime = (time) => {
  if (hovering.value) {
    let valid = true;
    calendar.value = calendar.value.map((el) => {
      if (el.time === time) {
        if (!el.booked) {
          el.booking = true;
        } else {
          valid = false;
        }
      }

      //On prévoit le cas ou le curseur est descendu trop bas et qu'on enleve des heures réservées
      if (moment(time, formatTime) < moment(el.time, formatTime)) {
        el.booking = false;

        //On reset le start si nécessaire
        if (
          moment(time, formatTime) <
          moment(newReservation.value.start, formatTime)
        ) {
          newReservation.value.start = time;
        }
      }
      return el;
    });

    if (!valid) {
      leaveClick(time);
    } else {
      newReservation.value.end = moment(time, formatTime)
        .add(minTime, "minutes")
        .format(formatTime);
      //Fix for last <li>
      if (newReservation.value.end > calendar.value.at(-1).time) {
        newReservation.value.end = calendar.value.at(-1).time;
      }
    }
    updateReservationTime();
  }
};

const updateReservationTime = () => {
  hoverSelectedSlot();
  emit("updateForm", { field: props.data.name, value: newReservation.value });
};

const hoverSelectedSlot = () => {
  //This function makes sure that every timeslot are correctly selected
  if (newReservation.value.start) {
    for (let i of calendar.value) {
      if (
        i.time >= newReservation.value.start &&
        i.time < newReservation.value.end
      ) {
        i.booking = true;
      }
    }
  }
};

const touchTime = (e) => {
  // TouchMove does not give the update time so we need to get it thanks to this quickfix
  const clientX = e.clientX || e.changedTouches[0].clientX;
  const clientY = e.clientY || e.changedTouches[0].clientY;
  const touchedTime = document
    .elementFromPoint(clientX, clientY)
    .getAttribute("data-time");
  hoverTime(touchedTime);
};

const leaveClick = (time) => {
  if (hovering.value) {
    hovering.value = false;
  }
};

const leaveTouch = (time) => {
  if (hovering.value) {
    hovering.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/main";

.room {
  margin: 2rem auto;
  box-shadow: $shadow;
  border: 2px solid $color-grey-75;
  display: block;
  padding: 0;
  width: 100%;
  @include respond(phone) {
    width: 100%;
  }

  &__closed{
    border: 1px solid $color-primary;;
    color:$color-primary;
    font-size: 3rem;
    padding: 2rem 3rem;
  }

  &__title {
    padding: 1rem 2rem;
    border-bottom: 1px solid currentColor;
  }

  &__content {
    touch-action: none;
  }

  & ul {
    margin: 4rem 0;
  }

  & li {
    padding: 1rem 2rem;
    list-style: none;
    position: relative;

    cursor: pointer;

    user-select: none;

    &:last-child {
      padding: 0 !important;
    }
  }

  &--line {
    border-top: 2px solid currentColor;
    &-large {
      padding: 1.6rem !important;
    }
  }

  &--interval {
    border-top: 1px solid #ccc;
  }

  &--hour {
    font-size: 1.2rem;
    position: absolute;
    top: calc(0% - 1rem);
    left: 0.4rem;
    transform: translate(0%, -50%);
    user-select: none;
  }

  &--booked {
    background-color: $color-red;
    cursor: not-allowed !important;
    &-user {
      background-color: $color-secondary !important;
    }
  }

  &--booking {
    background-color: $color-primary;
  }
}
</style>
