<template>
  <section id="offre" class="bgc--light">
    <div class="offre page__section">
      <div class="index__title">
        <h2>Notre offre</h2>
        <h3>
          Avant tout sur-mesure pour s'adapter aux besoins
          spécifiques des élèves.
        </h3>
        <div class="index__title--description">
          <p>
            <router-link to="{name:'contact'}" class="text-btn u-color-primary"
              >Contactez-nous</router-link
            >
            pour organiser une rencontre préalable en présence de votre (vos)
            enfant(s). C'est gratuit et sans engagement.
          </p>
        </div>
      </div>
      <ul class="offre__list">
        <li v-for="i of choice" class="offre__list--box">
          <div class="offre__list--icon">
            <SvgIcon size="3" :icon="i.icon"></SvgIcon>
          </div>
          <h4>
            {{ i.label }}
          </h4>
          <p class="offre__list--descr">
            {{ i.main }}
          </p>
          <ul v-for="l of i.features" class="offre__list--features">
            <li>
              <SvgIcon size="1.6" icon="plus"></SvgIcon>
              <p>
                {{ l }}
              </p>
            </li>
          </ul>
          <router-link class="btn__text" :to="i.to">
            {{ i.cta }}
            <SvgIcon size="1.4" icon="right-arrow"></SvgIcon>
          </router-link>
        </li>
      </ul>

      <div class="page__item">
        <h4>Nos préparations spécifiques</h4>
        <p>
          Au fil des années, nous avons développé des préparations spécifiques pour certaines épreuves :
        </p>
        <ul class="offre__tags">
          <li v-for="tag of formations">
            <router-link :to="tag.to">{{ tag.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import SvgIcon from "@/components/utils/SvgIcon";
import { ref } from "@vue/reactivity";
import formations from "@/composables/data/formations.json";
const selected = ref("particulier");

const choice = {
  particulier: {
    label: "Cours particuliers",
    icon: "particulier",
    main: "La formule idéale pour expliquer en profondeur une matière avec un prof spécialisé et expérimenté.",
    features: [
      // "Pas d'engagement exigé sur un nombre de séances",
      // "Fréquence et durée des cours au choix",
      "Feedback personnalisé envoyé aux parents après chaque cours",
    ],
    cta: "En savoir plus",
    to: {
      name: "cours",
    },
  },
  ateliers: {
    label: "Ateliers",
    icon: "atelier",
    main: "Séances de travail en groupe sur des matières précises: la formule qui fonctionne pour (re)faire des exercices et corriger ou préparer un contrôle.",
    features: [
      "Petits groupes de maximum 5 élèves pour un suivi personnalisé",
      // "Durée au choix durant les plages disponibles",
      // "Pas d'engagement exigé sur un nombre de séances",
    ],
    cta: "En savoir plus",
    to: {
      name: "ateliers",
    },
  },

  stages: {
    label: "Stages",
    icon: "stage-1",
    main: "Préparations en groupe en plusieurs séances sur des matières précises : la formule parfaite pour revoir une matière dans son entièreté.",
    features: [
      // "Petits groupes pour un suivi personnalisé",
      "Supports de cours (théorie et exercices corrigés)",
      // "Feedback personnalisé après chaque stage",
    ],
    cta: "En savoir plus",
    to: {
      name: "stages",
    },
  },
};
</script>

<style lang="scss">
@import "@/style/main";

.offre {
  &__tags {
    margin-top: 2rem;
    display: flex;
    & li {
      &:not(:last-child) {
        margin-right: 2rem;
      }

      & a {
        @include buttonNoStyle;
        background-color: #b1cfdf;
        border-radius: 5px;
        color: #010137;
        padding: 0.6rem 1.2rem;
        display: flex;
        transition: all 0.2s;
        &:hover {
          background-color: lighten(#b1cfdf, 6);
        }
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    margin-bottom: 4rem;

    @include respond(phone) {
      grid-template-columns: 1fr;
    }

    &--box{
      background-color: $color-white;
      padding: 2rem;
      border-radius: 5px;
    }

    &--descr {
      height: 6rem;

      @include respond(phone) {
        height: inherit;
      }
    }

    & li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      & h4 {
        font-size: 2rem;
        margin-bottom: 0.8rem;
      }
      & p {
        margin-bottom: 2rem;
      }

      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    & a {
      display: flex;
      align-items: center;
      align-self: flex-start;
      color: $color-secondary;
      margin-top: 2rem;
      & svg {
        margin-left: 2rem;
      }
    }

    &--icon {
      width: 5rem;
      height: 5rem;
      background-color: $color-secondary;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin-bottom: 1.6rem;
    }
    &--features {
      margin-top: 1.3rem;
      & li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0.6rem;

        & h4 {
        }
        & p {
          margin-bottom: 0;
        }
        & svg {
          margin-right: 2rem;
        }
      }
    }
  }
}
</style>
