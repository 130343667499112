<template>
  <div class="bgc--blue-dark">
    <div class="page__section footer">
      <div class="footer__access">
        <router-link
          :to="{ name: 'Access' }"
          class="btn btn--primary"
          style="color: inherit"
        >
          Plan d'accès
        </router-link>
      </div>
      <div class="footer__container">
        <div class="footer__logo">
          <img
            :src="
              strapi_url + '/uploads/medium_zappyschool_Copie_16215f81f2.png'
            "
            alt="Logo Zappy School - centre de soutien scolaire"
          />
        </div>
        <div class="footer__infos">
          <template v-for="key of Object.keys(data)">
            <div>
              <h4>
                {{ data[key].title }}
              </h4>
              <ul>
                <li v-for="l of data[key].list">
                  <a v-if="l.href" :href="l.href" :target="l.target ? l.target : ''">
                    <SvgIcon
                      :icon="l.icon"
                      size="2"
                      className="footer__item--logo"
                    ></SvgIcon>
                    <span v-html="l.label"></span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- <a v-if="item.href" :href="item.href" class="footer__item">
              <Svg
                :icon="item.icon"
                size="3"
                className="footer__item--logo"
              ></Svg>
              <span v-html="item.content"></span>
            </a>
            <div v-else class="footer__item">
              <Svg
                :icon="item.icon"
                size="3"
                className="footer__item--logo"
              ></Svg>
              <span v-html="item.content"></span>
            </div> -->
          </template>
        </div>
        <div class="footer__bottom">
          <div class="footer__bottom--item">
            © Zappy School ASBL {{ moment().format("YYYY") }}
          </div>
          <div class="footer__bottom--item">
            <ul>
              <li v-for="link of bottom">
                <router-link v-if="link.to" :to="link.to">{{
                  link.label
                }}</router-link>
                <a v-else-if="link.href" :href="link.href" target="_blank">{{
                  link.label
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Svg from "@/components/utils/Svg";
import SvgIcon from "@/components/utils/SvgIcon";
import moment from "moment";

const strapi_url = process.env.VUE_APP_STRAPI_DOMAIN;

const data = {
  website: {
    title: "Nos activités",
    list: [
      {
        icon: "home",
        href: "https://www.zappyschool.com",
        target:'_blank',
        label: "Accueil",
      },
      {
        icon: "login",
        href: "https://www.zappyschool.com/login.php",
        target:'_blank',
        label: "Se connecter",
      },
    ],
  },
 
  contact: {
    title: "Contactez-nous",
    list: [
      {
        icon: "phone",
        label: "02/374.46.48",
        href: "tel:003223744648",
      },
      {
        icon: "email",
        label: "info@zappyschool.com",
        href: "mailto:info@zappyschool.com",
      },
    ],
  },
  location: {
    title: "Notre centre",
    list: [
      {
        icon: "adress",
        label: "1384, Chaussée de waterloo<br>1180 Uccle Fort-Jaco",
        href: "https://goo.gl/maps/r1oVTjVybtULg8VY7",
        target:'_blank'
      },
    ],
  },
};

const bottom = [
  {
    to: { name: "privacy" },
    label: "Politique de confidentialité",
  },
  {
    to: {
      name: "terms",
    },
    label: "Conditions générales",
  },
  {
    href: "https://digital.onearth.be",
    label: "Powered by Digital On Earth ❤️",
  },
];
</script>

<style lang="scss">
@import "@/style/main";

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120rem;
  padding: 4rem 0rem;

  margin: 0 auto;
  width: 100%;

  &__access {
    position: absolute;
    top: 2rem;
    right: 0rem;
  }

  &__bottom {
    border-top: 2px solid currentColor;
    padding-top: 1rem;

    display: flex;
    justify-content: space-between;

    @include respond(phone){
      flex-direction: column;
    }
    &--item {
      & ul {
        display: flex;
        @include respond(phone){
          flex-direction: column;
          align-items: flex-end;
        }
      }

      & li {
        display: flex;
        align-items: center;

        

        &:not(:last-child) {
          position: relative;
          margin-right: 3rem;
          @include respond(phone){
            margin-right: 0;
            margin-bottom: .4rem;
            &::after{
              display: none;
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: calc(100% + 1.5rem);
            height: 0.5rem;
            width: 0.5rem;
            background-color: $color-white;
            border-radius: 50%;

            transform: translate(-50%, -50%);
          }
        }
      }

      & a {
        @include buttonNoStyle;
        @include hoverUnderline;

        
      }
    }
  }

  &__logo {
    height: 9rem;
    width: 100%;

    & img {
      height: 100%;
      width: auto;
    }
  }

  &__container {
    color: $color-white;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }
  }

  &__map {
    flex: 0 0 60%;
    width: 100%;
    @media only screen and (max-width: 750px) {
      margin-bottom: 4rem;
    }
  }

  &__infos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
    flex-direction: column;
    flex: 1;
    margin: 4rem 0;

    @include respond(phone){
      grid-template-columns: 1fr;
      row-gap: 2rem;
    }

    & h4{
      font-size: 2rem;
      margin-bottom: 2rem;
      text-decoration: underline;
    }

    & ul{
      display: flex;
      flex-direction: column;
    }
    & li{
      color:$color-white;

      &:not(:last-child){
        margin-bottom: 2rem
      }
    }

    & a{
      @include buttonNoStyle;

      display: flex;
      align-items: center;
      & span{
          @include hoverUnderline;
        }
    }

    & svg{
      margin-right: 1rem;
    }
  }

  &__item {
    text-decoration: none;

    display: flex;
    align-items: center;
    // color: $color-blue-dark;

    font-size: 1.8rem;
    letter-spacing: 0.1rem;

    & span {
      text-align: left;
    }

    &--logo {
      margin-right: 2rem;
    }
  }
}
</style>
