<template>
  <div class="table__container" v-if="schema">
    <h2 class="table__title">
      {{ schema.title }}
    </h2>
    <p
      class="table__description"
      v-if="schema.description"
      v-html="schema.description"
    ></p>
    <div
      v-if="schema.pagination && schema.pageLines && schema.totalLines"
      class="table__pagination"
    >
      <ul>
        <li
          v-for="i in Math.ceil(schema.totalLines / schema.pageLines)"
          :class="schema.page === i ? `table__pagination--selected` : ''"
        >
          <button @click="paginate(i)">
            {{ i }}
          </button>
        </li>
      </ul>
    </div>
    <div v-if="tableData.length" class="table">
      <table-header :schema="schema"></table-header>
      <template  v-for="(line, i) in tableData" :key="i">
        <table-line :line="line" :schema="schema"></table-line>
      <template v-if="line.children">
        <div class="table__children">
          <table-header :schema="line.children.schema"></table-header>
          <template  v-for="(lineChild, j) in line.children.data" :key="j">
            <table-line :line="lineChild" :schema="line.children.schema"></table-line>
          </template>
        </div>
        
      </template>
    </template>
    </div>
    <div v-else>
      {{ schema.empty ? schema.empty : "No Data" }}
    </div>
  </div>
</template>

<script setup>
import axios from "axios";

import { ref } from "@vue/reactivity";

import { useStore } from "vuex";
import { onMounted, watch } from "@vue/runtime-core";

import TableLine from "@/components/table/TableLine.vue";
import TableHeader from "@/components/table/TableHeader.vue";

const props = defineProps(["data", "schema"]);
const emit = defineEmits(["updateTable", "updatePage"]);

const store = useStore();
const tableData = ref(props.data);

const sortData = (col) => {
  tableData.value.sort((a, b) =>
    a[col] > b[col] ? 1 : b[col] > a[col] ? -1 : 0
  );
};

const paginate = (pageNumber) => {
  emit("updatePage", { pageNumber });
};

const handleFormData = (val, lineData) => {
  emit("updateTable", { line: lineData, newVal: val.data });
};
</script>

<style lang="scss" ped>
@import "@/style/main";
@import "@/style/components/table";
</style>
