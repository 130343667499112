<template>
  <section id="approche" class="bgc--white">
    <div class="approche page__section">
      <div class="index__title">
        <h2>Notre approche</h2>
        <h3>Susciter la motivation des élèves du secondaire</h3>
      </div>

      <ul class="approche__features">
        <li v-for="i of icons" class="approche__item">
          <SvgIcon size="2.8" :icon="i.icon"></SvgIcon>
          {{ i.label }}
        </li>
      </ul>

      <p>
        Notre approche peut se résumer en une phrase :
        <b>aider à réussir en redonnant de l'envie aux élèves.</b>
      </p>
      <p>
        Nous constatons très souvent que le niveau de motivation des élèves pour
        leur scolarité est faible. Si on demandait aux 25 élèves d'une classe de
        secondaire lesquels d'entre eux s'engagent véritablement à 100% pour
        l'école, combien de bras se lèveraient ? Et les difficultés scolaires
        trouvent très souvent leur origine dans ce manque de motivation.
        Redonner de l'envie est donc pour nous le point de départ nécessaire à
        la réussite.
      </p>
      <p>
        D'où provient cette situation ? Chaque cas est spécifique mais une
        constante apparaît clairement : le système scolaire traditionnel répond
        très mal aux besoins des adolescents du 21e siècle, à commencer par leur
        besoin d'être écouté et de voir leurs avis pris en compte, ce qui
        dégrade leur envie naturelle d'apprendre.
      </p>
      <p>Notre approche s'articule donc autour de deux axes :</p>
      <p>
        1. Nous laissons aux élèves (et à leurs parents) carte blanche pour
        décider de la fréquence et de la durée des séances individuelles ou en
        groupe qu'ils viennent suivre chez nous. Tout est à la carte, tout est
        sur-mesure, et notre centre est ouvert 7 jours sur 7. Implicitement,
        nous faisons confiance aux élèves, nous les considérons comme
        suffisamment responsables et les amenons à se prendre en main, ce qui
        les amène à percevoir l'apprentissage plus positivement et avec une
        nouvelle énergie.
      </p>
      <p>
        2. Nous accordons la plus grande attention à la qualité de nos profs et
        à la qualité de la relation qui se noue avec les élèves durant les
        séances. L'écoute, le respect mutuel, le non-jugement et la confiance en
        sont les valeurs essentielles et les étudiants universitaires qui
        constituent la majorité de notre équipe de profs sont particulièrement
        aptes à établir ce type de relation avec les élèves
      </p>
    </div>
  </section>
</template>

<script setup>
import SvgIcon from "@/components/utils/SvgIcon.vue";

const icons = [
  {
    icon: "ecoute",
    label: "Ecoute",
  },
  {
    icon: "jump",
    label: "Confiance",
  },
  {
    icon: "respect",
    label: "Respect",
  },
  {
    icon: "croissance",
    label: "Responsabilisation",
  },
  {
    icon: "handshake",
    label: "Autonomie",
  },
];
</script>

<style lang="scss">
@import "@/style/main";
.approche {
  &__features {
    margin-bottom: 5rem;
    display: flex;
    // justify-content: space-between;
    width: 100%;

    @include respond(phone){
      flex-direction: column;
    }
  }

  &__item {
    background-color: $color-blue-main;
    color: $color-white;
    border-radius: 0.3rem;

    display: flex;
    flex-wrap: wrap;

    // flex-direction: column;
    align-items: center;

    padding: 0.8rem 2rem;

    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;


    & svg {
      margin-right: 1.8rem;
    }

    @include respond(tab-land) {
      padding: .7rem 1.4rem;
      font-size: 1.5rem;
    }

    &:not(:last-child) {
      margin-right: 2rem;

      @include respond(phone){
      margin-right: 0;
        margin-bottom: 2rem;
      }
    }
  }

  & p {
    font-size: 1.6rem;
    text-align: justify;

    &:not(:last-child){
      margin-bottom: 2rem;
    }
  }
}
</style>
